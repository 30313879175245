import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { _buscarPagamento } from "services/CheckoutService";
import { PixPayment } from "./components/PixDetails";
import { CreditCardDetails } from "./components/CreditCardDetails";
import { InitialDetails } from "./components/InitialDetails";
import { CardPayment } from "./components/CardPayment";
import PaymentNotFound from "./components/ScreenMessages/paymentNotFound";
import { ErrorPage } from "./components/ScreenMessages/errorPage";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { PaymentInfo } from "./components/InitialDetails/components/PaymentInfo";
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export const Checkout = () => {
  const location = useLocation();
  const { idCliente, hash } = useParams();
  const [currentStep, setCurrentStep] = useState("initial");
  const [clientData, setClientData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [validHash, setValidHash] = useState(true);
  const [returnMessage, setReturnMessage] = useState("");

  const initialTimeLeft = location.state?.timeLeft;
  const [timeLeft, setTimeLeft] = useState(initialTimeLeft || 0);

  const hasExpired = (dataCriacao) => {
    const creationTime = dayjs.tz(
      dataCriacao,
      "DD/MM/YYYY HH:mm",
      "America/Sao_Paulo"
    );
    const currentTime = dayjs().tz("America/Sao_Paulo");
    return !creationTime.add(20, "minute").isAfter(currentTime);
  };

  useEffect(() => {
    if (!idCliente || !hash || !validHash) {
      setReturnMessage("Pagamento Não Encontrado");
      setValidHash(false);
      setLoading(false);
      return;
    }

    _buscarPagamento(idCliente, hash)
      .then((response) => {
        if (!response.data) {
          throw new Error("Informações de pagamento inválidas");
        }

        if (response.data.pagamento.origem === "CORPORA_BIO") {
          if (hasExpired(response.data.pagamento.dataCriacao)) {
            setReturnMessage("Pagamento expirado");
            setValidHash(false);
            setLoading(false);
            return;
          }
        }

        if (response.data.pagamento.status === "RECEBIDO") {
          setReturnMessage("Pagamento já foi realizado.");
          setValidHash(false);
          setLoading(false);
          return;
        }

        setClientData(response.data);
      })
      .catch((err) => {
        console.error(err);
        setValidHash(false);
        setReturnMessage("Erro ao processar pagamento");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [idCliente, hash]);

  const handleProceedToPayment = (paymentType) => {
    setCurrentStep(paymentType);
  };

  const handleBackToInitial = (step) => {
    setCurrentStep(step);
  };

  const renderStep = (step) => {
    if (loading) {
      return <div>Carregando...</div>;
    }

    if (!validHash) {
      return <PaymentNotFound message={returnMessage} />;
    }

    switch (step) {
      case "initial":
        return (
          <InitialDetails
            clientData={clientData}
            onProceedToPayment={handleProceedToPayment}
          />
        );
      case "CARTAO_CREDITO":
      case "BOLETO":
        return (
          <CreditCardDetails
            currentStep={currentStep}
            idCliente={idCliente}
            hash={hash}
            clientData={clientData}
            onBackToInitial={handleBackToInitial}
          />
        );
      case "PIX":
        return (
          <PixPayment
            clientData={clientData}
            idCliente={idCliente}
            hash={hash}
            initialTimeLeft={timeLeft}
            onBackToInitial={handleBackToInitial}
          />
        );
      case "PAYMENT_INFO":
        return (
          <PaymentInfo
            clientData={clientData}
            onProceedToPayment={handleProceedToPayment}
            onBackToInitial={handleBackToInitial}
          />
        );
      default:
        return <ErrorPage message="Passo de pagamento inválido." />;
    }
  };

  return <CardPayment>{renderStep(currentStep)}</CardPayment>;
};
