import React from "react";

const Tags = ({ stars, selectedTags, setSelectedTags }) => {
  const positiveTags = [
    "Muito profissional", 
    "Praticidade", "Valor acessível",
    "Atendimento humanizado",
    "Muito atenciosa", "Estacionamento",
    "Bem humorada", "Educação",
    "Pontualidade", "Organização",
    "Disponibilidade", "Local agradável", "Agendamento simples"
    
  ];
  const negativeTags = [
    "Atendimento robotizado",
    "Pouco profissional", "Estacionamento",
    "Pontualidade", "Demora no atendimento",
    "Falta de atenção", "Local inadequado",
    "Educação", "Desorganização",
    "Valor da consulta", "Dificuldade no agendamento"
  ];

  const tagsToShow = stars <= 2 ? negativeTags : stars >= 4 ? positiveTags : [];

  const handleTagClick = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((t) => t !== tag));
    } else if (selectedTags.length < 5) {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  return (
    <div className="flex flex-col gap-4 mt-4">
      {tagsToShow.length > 0 && (
        <label className="text-md font-light text-gray-700">
          {stars <= 2
            ? "Selecione até 5 problemas"
            : "Selecione até 5 qualidades"}
        </label>
      )}

      <div className="flex flex-wrap justify-center items-center gap-2">
        {tagsToShow.map((tag, index) => (
          <div
            key={index}
            onClick={() => handleTagClick(tag)}
            className={`px-3 py-1 text-sm font-medium rounded-full cursor-pointer border ${
              selectedTags.includes(tag)
                ? "bg-indigo-700 text-white border-indigo-700"
                : "bg-white text-gray-700 border-gray-300"
            } ${
              selectedTags.length >= 5 && !selectedTags.includes(tag)
                ? "cursor-not-allowed opacity-50"
                : ""
            }`}
          >
            {tag}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tags;
