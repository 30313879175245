import React from "react";

const FeedbackSuccess = () => {
  return (
    <div className="flex flex-col items-center p-6 rounded-lg mt-8 w-full max-w-md mx-auto">
      <h2 className="text-2xl font-semibold text-gray-700 text-center">
        Obrigado por nos enviar seu feedback!
      </h2>
      <a href="https://www.usecorpora.com.br" className="mb-4">
        <img
          src="/img/logo/logo-light-full.png"
          alt="Company Logo"
          className="w-52 mx-auto"
        />
      </a>
    </div>
  );
};

export default FeedbackSuccess;
