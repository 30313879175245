import React, { useState, useEffect } from "react";
import { MdContentCopy } from "react-icons/md";
import pixImage from "assets/images/pix-106.png";
import {
  __buscarPagamentoForma,
  _buscarPagamento,
} from "services/CheckoutService";
import { toast, ToastContainer } from "react-toastify";
import { SuccessPage } from "../ScreenMessages/success";
import { ErrorPage } from "../ScreenMessages/errorPage";
import { criarFormatador } from "utils/moneyformatter";
import Spinner from "components/ui/Spinner";
import "./styles.css";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { FiArrowLeft } from "react-icons/fi";
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export const PixPayment = ({ idCliente, hash, clientData, onBackToInitial }) => {
  const formatador = criarFormatador();
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingQRCode, setLoadingQRCode] = useState(true);
  const [timeLeft, setTimeLeft] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState(
    clientData?.pagamento.status
  );

  useEffect(() => {
    if (idCliente && hash) {
      __buscarPagamentoForma(idCliente, hash, "PIX")
        .then((response) => {
          setPaymentResponse(response.data);
          setLoadingQRCode(false);
        })
        .catch((err) => {
          console.error("Erro ao buscar pagamento:", err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [idCliente, hash]);

  useEffect(() => {
    const checkPaymentStatus = () => {
      _buscarPagamento(idCliente, hash)
        .then((response) => {
          const newStatus = response.data.pagamento.status;
          setPaymentStatus(newStatus);
        })
        .catch((err) => {
          console.error("Erro ao verificar o status do pagamento:", err);
        });
    };

    const intervalId = setInterval(checkPaymentStatus, 30000);

    return () => clearInterval(intervalId);
  }, [idCliente, hash]);

  useEffect(() => {
    const totalTime = 20 * 60 * 1000;

    if (clientData.pagamento.origem === "CORPORA_BIO") {
      const dataCriacao = dayjs.tz(
        clientData.pagamento.dataCriacao,
        "DD/MM/YYYY HH:mm",
        "America/Sao_Paulo"
      );
      const currentTime = dayjs().tz("America/Sao_Paulo");
      const timeElapsed = Math.abs(
        dataCriacao.diff(currentTime, "milliseconds")
      );
      const timeRemaining = totalTime - timeElapsed;

      if (timeRemaining > 0) {
        setTimeLeft(Math.floor(timeRemaining / 1000));
      } else {
        setTimeLeft(0);
      }
    } else {
      setTimeLeft(totalTime / 1000);
    }
  }, [clientData]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(paymentResponse?.trnsPixCopiaCola);
    toast.success("Código copiado!");
  };

  if (paymentStatus === "RECEBIDO") {
    return <SuccessPage clientData={clientData} isPix={true} />;
  }

  if (paymentStatus === "CANCELADO") {
    return <ErrorPage isPix={true} />;
  }

  const valuePaymentPix = clientData.pagamento.valor + clientData.valorTaxaPix;

  return (
    <>
      <div>
        <ToastContainer />
        <div className="flex items-center w-full max-w-md mb-4">
          <button
            onClick={() => onBackToInitial("PAYMENT_INFO")}
            className="flex items-center gap-2 text-indigo-800 font-medium"
          >
            <FiArrowLeft className="text-xl" />
            Voltar
          </button>
        </div>
        <div className="pix-payment-container">
          <div className="pix-payment">
            {timeLeft > 0 ? (
              loadingQRCode ? (
                <Spinner size={40} />
              ) : (
                <>
                  <div className="qr-code-text">
                    <h3>Escaneie o QRCODE</h3>
                    <p>para pagar com Pix</p>
                  </div>
                  <div className="qr-code-container">
                    <img
                      src={`data:image/png;base64,${paymentResponse?.trnsPixQrCode}`}
                      alt="QR Code"
                      className="qr-code-image"
                    />
                  </div>
                  <div className="pix-code-container">
                    <input
                      type="text"
                      value={paymentResponse?.trnsPixCopiaCola}
                      readOnly
                      className="pix-code-input"
                    />
                    <button onClick={handleCopy} className="copy-button">
                      <MdContentCopy />
                      Copiar código
                    </button>
                  </div>
                  <div className="countdown-timer">
                    <strong>Tempo restante:</strong> {formatTime(timeLeft)}
                  </div>
                </>
              )
            ) : (
              <div className="pix-expired">
                <h3>
                  {clientData.pagamento.origem === "CORPORA_BIO"
                    ? "Pix Expirado"
                    : "Tempo de Pix excedido"}
                </h3>
              </div>
            )}
          </div>
          <div className="separator-line-pix"></div>
          <div className="payment-info">
            <h3>Resumo de Pagamento</h3>
            <div className="payment-detail">
              <p>
                <strong>Nome do Produto</strong>
              </p>
              <p>{clientData.pagamento.titulo}</p>
            </div>
            <div className="payment-detail">
              <p>
                <strong>Proprietário da Conta</strong>
              </p>
              <p>{clientData.nomeProfissional}</p>
            </div>
            <div className="payment-detail">
              <p>
                <strong>Valor do Pix</strong>
              </p>
              <p>{formatador.format(valuePaymentPix)}</p>
            </div>
            <div className="pix-image-container">
              <img src={pixImage} alt="Pix Image" className="pix-image" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
