import React, { useEffect, useState } from "react";
import { Button, Notification, Steps, toast } from "components/ui";

import { DateTime } from "luxon";
import { useNavigate, useParams } from "react-router-dom";
import {
  _atualizarCliente,
  _buscarPacientePorId,
} from "services/PacienteService";

import DadosPessoais from "./FormSteps/DadosPessoais";
import ResponsavelFinanceiro from "./FormSteps/ResponsavelFinanceiro";
import Endereco from "./FormSteps/Endereco";
import PlanoSaude from "./FormSteps/PlanoSaude";

import { TbError404 } from "react-icons/tb";
import { Loading } from "components/shared";

export default function AtualizarPaciente({ uuidPaciente, urlAgenda }) {
  const [stage, setStage] = useState(0);
  const [cliente, setCliente] = useState({});

  const [dadosPessoais, setDadosPessoais] = useState({});
  const [responsavelFinanceiro, setResponsavelFinanceiro] = useState({});
  const [endereco, setEndereco] = useState({});
  const [planoSaude, setPlanoSaude] = useState({});
  const [finished, setFinished] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [clienteNotFound, setClienteNotFound] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    buscarCliente();
  }, [uuidPaciente]);

  const buscarCliente = async () => {
    try {
      setLoading(true);
      const response = await _buscarPacientePorId(uuidPaciente);
      response.data.dataNascimento = response.data.dataNascimento
        ? DateTime.fromFormat(
            response.data.dataNascimento,
            "dd/MM/yyyy"
          ).toFormat("yyyy-MM-dd")
        : "";

      setCliente(response.data);

      setDadosPessoais({
        nome: response.data.nome,
        profissao: response.data.profissao,
        email: response.data.email,
        telefone: response.data.telefone || "",
        cpf: response.data.cpf,
        dataNascimento: response.data.dataNascimento,
        genero: response.data.genero,
      });

      setEndereco({
        cep: response.data.cep,
        endereco: response.data.endereco,
        numero: response.data.numero,
        bairro: response.data.bairro,
        complemento: response.data.complemento,
        cidade: response.data.cidade,
        estado: response.data.estado,
      });

      setResponsavelFinanceiro({
        nomeResponsavelFinanceiro: response.data.nomeResponsavelFinanceiro,
        telefoneResponsavelFinanceiro:
          response.data.telefoneResponsavelFinanceiro || "",
      });

      setPlanoSaude({
        nomePlano: response.data.nomePlano,
        codigoPlano: response.data.codigoPlano,
        validadePlano: response.data.validadePlano,
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Erro ao buscar cliente ", error);
      setClienteNotFound(true);
    }
  };

  const StageWrapper = ({ children, selectedStage }) => {
    if (selectedStage === stage) {
      return children;
    }
  };

  const handlePrevStage = () => {
    setStage(stage - 1);
    setFinished(false);
  };

  const handleNextStage = () => setStage(stage + 1);

  useEffect(() => {
    if (finished) {
      console.log("ENTREI PARA SUBMEETER")
      handleSubmitValues();
    }
  }, [finished]);

  const handleSubmitValues = async () => {
    setIsSubmitting(true);

    console.log(dadosPessoais.dataNascimento)

    const payload = {
      uuid: uuidPaciente,
      nome: dadosPessoais.nome,
      profissao: dadosPessoais.profissao,
      email: dadosPessoais.email,
      telefone: dadosPessoais.telefone,
      cpf: dadosPessoais.cpf.replace(/\D/g, ""),
      dataNascimento: dadosPessoais.dataNascimento ? DateTime.fromFormat(
        dadosPessoais.dataNascimento,
        "yyyy-MM-dd"
      ).toFormat("dd/MM/yyyy") : '',
      genero: dadosPessoais.genero,
      nomeResponsavelFinanceiro:
        responsavelFinanceiro.nomeResponsavelFinanceiro,
      telefoneResponsavelFinanceiro:
        responsavelFinanceiro.telefoneResponsavelFinanceiro,
      cep: endereco.cep?.replace(/\D/g, ""),
      endereco: endereco.endereco,
      cidade: endereco.cidade,
      estado: endereco.estado,
      bairro: endereco.bairro,
      numero: endereco.numero,
      complemento: endereco.complemento,
      timezone: endereco.timezone,
      nomePlano: planoSaude.nomePlano,
      codigoPlano: planoSaude.codigoPlano,
      validadePlano: planoSaude.validadePlano,
    };

    try {
      await _atualizarCliente(payload);
      toast.push(
        <Notification type="success">
          Dados atualizados com sucesso
        </Notification>,
        {
          placement: "top-center",
        }
      );

      setTimeout(() => {
        setIsSubmitting(false);
        setStage(4);
      }, 500);
    } catch (error) {
      setIsSubmitting(false);
      toast.push(
        <Notification type="danger">
          Não foi possível atualizar. Verifique os dados inseridos e tente
          novamente.
        </Notification>,
        {
          placement: "top-center",
        }
      );
      setFinished(false);
    }
  };

  const stages = {
    0: "Dados pessoais",
    1: "Responsável financeiro",
    2: "Endereço",
    3: "Plano de saúde",
  };

  //   const Welcome = () => (
  //     <div className="flex flex-col items-center justify-center max-w-xl">
  //       <h2 className="mt-5 font-light">Bem-vindo à Corpora</h2>
  //       <p className="mb-5 text-center">
  //         Ao completar seu cadastro você ajuda o profissional a saber mais sobre
  //         você e como ele pode te atender melhor.
  //       </p>
  //       <Button variant="solid" size="md">
  //         Iniciar
  //       </Button>
  //       <p className="mt-2">
  //         <small>
  //           <a href="https://usecorpora.com.br/termos-de-uso/?_gl=1*1g5b5v9*_gcl_au*MTA4NDQzNTY0MS4xNzI5NTk3MTQx">
  //             Termos de uso
  //           </a>
  //         </small>
  //         <small> &#x2022; </small>
  //         <small>
  //           <a href="https://usecorpora.com.br/politica-de-privacidade/">
  //             Política de privacidade
  //           </a>
  //         </small>
  //       </p>
  //     </div>
  //   );

  const Completed = () => (
    <div className="flex flex-col items-center justify-center max-w-xl">
      <h2 className="mt-5 font-light">Obrigado</h2>
      <p className="text-center mt-4 mb-2">
        Seu cadastro foi atualizado com sucesso!
      </p>
      <div className="flex gap-2">
        <Button size="md" onClick={() => window.close()}>
          Fechar
        </Button>
        <Button
          variant="solid"
          size="md"
          onClick={() => {
            const el = document.createElement("a");
            el.href = `https://corpora.bio/${urlAgenda}`;
            document.body.appendChild(el);
            el.click();
          }}
        >
          Agendar
        </Button>
      </div>
      <p className="mt-2">
        <small>
          <a href="https://usecorpora.com.br/termos-de-uso/?_gl=1*1g5b5v9*_gcl_au*MTA4NDQzNTY0MS4xNzI5NTk3MTQx">
            Termos de uso
          </a>
        </small>
        <small> &#x2022; </small>
        <small>
          <a href="https://usecorpora.com.br/politica-de-privacidade/">
            Política de privacidade
          </a>
        </small>
      </p>
    </div>
  );

  if (clienteNotFound) {
    return (
      <div className="py-10 flex flex-col items-center justify-center max-w-xl">
        <img
          src="/img/logo/logo-light-full.png"
          className="max-w-xs"
          alt="logo"
        />

        <TbError404 size={128} className="text-indigo-600" />
        <h2 className="mt-3 mb-2 font-light">Link inválido</h2>
        <p className="text-center">
          O link que você clicou não retornou nenhum resultado.
        </p>
        <p className="text-center">
          Confirme o link com o profissional e verifique se você já enviou os
          dados.
        </p>
        <p className="text-center">
          Se necessário, solicite ao profissional para gerar um novo link
        </p>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex flex-auto flex-col h-[100vh]">
        <Loading loading />
      </div>
    );
  }

  return (
    <div className="py-10 flex flex-col items-center justify-center">
      <img
        src="/img/logo/logo-light-full.png"
        className="max-w-xs"
        alt="logo"
      />

      {/* <StageWrapper selectedStage={0}>
        <Welcome />
      </StageWrapper> */}

      {stage !== 4 && (
        <>
          <div className="pt-6 pb-4 hidden md:block">
            <Steps current={stage + 1}>
              <Steps.Item title="Dados pessoais" />
              <Steps.Item title="Responsável financeiro" />
              <Steps.Item title="Endereço" />
              <Steps.Item title="Plano de saúde" />
            </Steps>
          </div>
          <div className="py-4 flex gap-4 md:hidden">
            <Steps current={stage}>
              <Steps.Item title={stages[stage]}></Steps.Item>
            </Steps>
          </div>
        </>
      )}

      <StageWrapper selectedStage={0}>
        <DadosPessoais
          defaultValues={dadosPessoais}
          onSubmit={(values) => {
            setDadosPessoais(values);
            handleNextStage();
          }}
        />
      </StageWrapper>

      <StageWrapper selectedStage={1}>
        <ResponsavelFinanceiro
          defaultValues={responsavelFinanceiro}
          onSubmit={(values) => {
            setResponsavelFinanceiro(values);
            handleNextStage();
          }}
          onBack={handlePrevStage}
        />
      </StageWrapper>

      <StageWrapper selectedStage={2}>
        <Endereco
          defaultValues={endereco}
          onBack={handlePrevStage}
          onSubmit={(values) => {
            setEndereco(values);
            handleNextStage();
          }}
          cliente={cliente}
        />
      </StageWrapper>
      <StageWrapper selectedStage={3}>
        <PlanoSaude
          defaultValues={planoSaude}
          onBack={handlePrevStage}
          isSubmitting={isSubmitting}
          onSubmit={(values) => {
            setPlanoSaude(values);
            setFinished(true);
          }}
        />
      </StageWrapper>

      <StageWrapper selectedStage={4}>
        <Completed />
      </StageWrapper>
    </div>
  );
}
