function validateCPF(cpf) {
  cpf = cpf.replace(/\D/g, "");

  if (cpf.length !== 11) {
    return false;
  }

  if (/^(\d)\1+$/.test(cpf)) {
    return false;
  }

  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i);
  }
  let firstCheckDigit = 11 - (sum % 11);
  if (firstCheckDigit === 10 || firstCheckDigit === 11) {
    firstCheckDigit = 0;
  }

  if (firstCheckDigit !== parseInt(cpf.charAt(9))) {
    return false;
  }

  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i);
  }
  let secondCheckDigit = 11 - (sum % 11);
  if (secondCheckDigit === 10 || secondCheckDigit === 11) {
    secondCheckDigit = 0;
  }

  if (secondCheckDigit !== parseInt(cpf.charAt(10))) {
    return false;
  }

  return true;
}

function validateNIF(nif) {
  if (!/^\d{9}$/.test(nif)) {
    return false;
  }

  const validFirstDigits = [1, 2, 3, 5, 6, 8, 9];
  const firstDigit = parseInt(nif.charAt(0), 10);

  if (!validFirstDigits.includes(firstDigit)) {
    return false;
  }

  let sum = 0;
  for (let i = 0; i < 8; i++) {
    sum += parseInt(nif.charAt(i), 10) * (9 - i);
  }
  const checkDigit = 11 - (sum % 11);
  const calculatedDigit = checkDigit >= 10 ? 0 : checkDigit;

  return calculatedDigit === parseInt(nif.charAt(8), 10);
}

function validateDocument(document, type) {
  const validate = {
    CPF: validateCPF,
    NIF: validateNIF,
  };

  return validate[type](document);
}

export default validateDocument;
