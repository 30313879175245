import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useParams } from "react-router-dom";
import "./styles.css";
import { FaArrowLeft, FaCheck } from "react-icons/fa";
import { criarFormatador } from "utils/moneyformatter";
import { FiArrowLeft } from "react-icons/fi";

const CheckoutForm = ({ onBackToInitial, clientData }) => {
  const formatador = criarFormatador();
  const { idCliente, hash } = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isPaymentElementReady, setPaymentElementReady] = useState(false);

  useEffect(() => {
    if (elements) {
      const paymentElement = elements.getElement(PaymentElement);
      paymentElement.on("ready", () => {
        setPaymentElementReady(true);
      });
    }
  }, [elements]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const return_url = `${window.location.origin}/${idCliente}/pay/${hash}/payment-status`;

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: { return_url },
    });

    if (error) {
      setErrorMessage(error.message);
    }
  };

  const valuePaymentCard =
    clientData.pagamento.valor + clientData.valorTaxaCartao;

  return (
    <>
      <div className="creditCard-container">
        <div className="flex items-center w-full max-w-md mb-4">
          <button
            onClick={() => onBackToInitial("PAYMENT_INFO")}
            className="flex items-center gap-2 text-indigo-800 font-medium"
          >
            <FiArrowLeft className="text-xl" />
            Voltar
          </button>
        </div>
        {isPaymentElementReady && (
          <div className="mb-8">
            <span className="block text-lg">
              <strong>Cliente: </strong>
              {clientData.cliente.nome}
            </span>
            <span className="text-lg">
              <strong>Valor a ser cobrado:</strong>{" "}
              {formatador.format(valuePaymentCard)}
            </span>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <PaymentElement />
          {isPaymentElementReady && (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <button type="submit" className="submit-button items-center">
                <FaCheck /> Concluir Pagamento
              </button>
            </div>
          )}
          {errorMessage && <div>{errorMessage}</div>}
        </form>
      </div>
    </>
  );
};

export default CheckoutForm;
