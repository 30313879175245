import React, { useEffect, useState } from "react";
import { Button, FormContainer, FormItem, Input, Select } from "components/ui";
import { Field, Form, Formik } from "formik";
import InputMask from "react-input-mask";

import timezones from "utils/timezones";
import { t } from "i18next";

import * as Yup from "yup";
import buscarEnderecoPorCEP from "utils/findCEP";

export default function Endereco({ defaultValues, onSubmit, cliente, onBack }) {
  const [selectedTimeZone, setSelectedTimeZone] = useState({});

  const generateOptions = (list) =>
    list
      .map((item) => {
        return {
          label: item.label,
          value: item.tzCode,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (cliente?.timezone) {
      setSelectedTimeZone(
        generateOptions(timezones).find((tz) => tz.value === cliente.timezone)
      );
    } else {
      setSelectedTimeZone(
        generateOptions(timezones).find(
          (tz) => tz.value === Intl.DateTimeFormat().resolvedOptions().timeZone
        )
      );
    }
  }, [cliente]);

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={(values) => {
        values.timezone = selectedTimeZone.value;
        onSubmit(values);
      }}
    >
      {({ values, touched, setFieldValue, setFieldTouched }) => {
        const cepStr = values.cep?.replace(/\D/g, "");

        if (touched.cep && cepStr?.length >= 8) {
          buscarEnderecoPorCEP(cepStr)
            .then((res) => {
              setFieldValue("cidade", res.cidade);
              setFieldValue("endereco", res.endereco);
              setFieldValue("bairro", res.bairro);
              setFieldValue("estado", res.estado);
              setFieldTouched("cep", false);
            })
            .catch(() => setFieldTouched("cep", false));
        }

        return (
          <Form className="w-full">
            <FormContainer>
              <div className="grid grid-cols-1 sm:grid-cols-12 gap-1">
                <FormItem label="CEP" className="col-span-3">
                  <Field name="cep">
                    {({ field }) => (
                      <div>
                        <InputMask
                          {...field}
                          mask="99999-999"
                          placeholder="CEP"
                        >
                          {(inputProps) => (
                            <Input
                              {...inputProps}
                              type="text"
                              autoComplete="off"
                            />
                          )}
                        </InputMask>
                      </div>
                    )}
                  </Field>
                </FormItem>
                <FormItem label="Endereço" className="col-span-6">
                  <Field
                    type="text"
                    autoComplete="off"
                    name="endereco"
                    placeholder="Endereço"
                    component={Input}
                  />
                </FormItem>
                <FormItem label="Número" className="col-span-3">
                  <Field
                    type="text"
                    autoComplete="off"
                    name="numero"
                    placeholder="Número"
                    component={Input}
                  />
                </FormItem>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
                <FormItem label="Bairro">
                  <Field
                    type="text"
                    autoComplete="off"
                    name="bairro"
                    placeholder="Bairro"
                    component={Input}
                  />
                </FormItem>

                <FormItem label="Complemento">
                  <Field
                    type="text"
                    autoComplete="off"
                    name="complemento"
                    placeholder={"Complemento"}
                    component={Input}
                  />
                </FormItem>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
                <FormItem label="Cidade">
                  <Field
                    type="text"
                    autoComplete="off"
                    name="cidade"
                    placeholder="Cidade"
                    component={Input}
                  />
                </FormItem>

                <FormItem label="Estado">
                  <Field
                    type="text"
                    autoComplete="off"
                    name="estado"
                    placeholder={t("estado.label")}
                    maxLength={`${t("estado.size")}`}
                    component={Input}
                  />
                </FormItem>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
                <div className="">
                  <label>Fuso horário</label>
                  <Select
                    options={generateOptions(timezones)}
                    value={selectedTimeZone}
                    onChange={(option) => setSelectedTimeZone(option)}
                  />
                </div>
              </div>

              <FormItem>
                <div className="flex justify-between mt-4">
                  <Button onClick={onBack}>Voltar</Button>
                  <Button type="submit" variant="solid">
                    Próximo
                  </Button>
                </div>
              </FormItem>
            </FormContainer>
          </Form>
        );
      }}
    </Formik>
  );
}
