const personalDocuments = {
  BR: {
    name: "CPF",
    mask: "999.999.999-99",
  },
  PT: {
    name: "NIF",
    mask: "999999999",
  },
};

export default personalDocuments;
