const configDEV = {
    apiPrefix: 'https://dbf6-177-152-62-46.ngrok-free.app/corpora',
    authenticatedEntryPath: "/agenda",
    unAuthenticatedEntryPath: '/agenda',
    tourPath: '/',
    locale: 'pt-br',
    enableMock: false,
    nome: "desenvolvimento",
    versao: "dev"
}

const configPROD = {
    apiPrefix: 'https://usecorpora.app.br/corpora',
    authenticatedEntryPath: "/agenda",
    unAuthenticatedEntryPath: '/agenda',
    tourPath: '/',
    locale: 'pt-br',
    enableMock: false,
    nome: "producao",
    versao: "p.1.4.26"
}

const appConfig = process.env.NODE_ENV === "production" ? configPROD : configDEV

console.log(process.env.NODE_ENV)

export default appConfig