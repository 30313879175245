import React from "react";
import { formatCPF } from "utils/format";
import { PaymentDetails } from "./PaymentDetails";
import { Button } from "components/ui";

export const PurchaseDetails = ({ clientData, onProceedToPayment }) => {

  const nomeProfissional = clientData?.nomeProfissional || "";
  const cpfProfissional = formatCPF(clientData?.cpfProfissional);
  const valorPagamento = clientData?.pagamento?.valor || 0;
  const fotoUrl = clientData?.urlLogo || clientData?.urlFoto;
  const lancamentos = clientData?.lancamentos || [];
  const pagamento = clientData?.pagamento || {};
  const pacienteNome = clientData?.cliente?.nome || "N/A";
  const pacienteCpf = formatCPF(clientData?.cliente?.cpf || "");
  const infoConselho = clientData?.infoConselho;

  return (
    <div className="flex flex-col items-center w-full">
      <h4 className="text-indigo-800 text-xl text-center mb-4">
        Checkout de Pagamento
      </h4>

      <div className="flex flex-col items-center w-full mb-5">
        {fotoUrl ? (
          <img
            src={fotoUrl}
            alt="Foto do Profissional"
            className="w-24 h-24 sm:w-40 sm:h-40 rounded-full object-cover border-4 border-indigo-700 mb-3"
          />
        ) : (
          <div className="w-24 h-24 sm:w-40 sm:h-40 rounded-full bg-gray-300 border-4 border-indigo-700 mb-3"></div>
        )}
        <div className="text-center">
          <div className="text-lg sm:text-xl font-bold text-gray-800">
            {nomeProfissional}
          </div>
          <div className="text-sm text-gray-600">{cpfProfissional}</div>
          {infoConselho && (
            <div className="text-sm text-gray-600">{infoConselho}</div>
          )}
        </div>
      </div>

      <div className="w-full bg-white rounded-lg drop-shadow p-4 mb-5">
        <h5 className="text-lg font-bold text-indigo-800 mb-4">
          Informações do Paciente
        </h5>
        <div className="flex flex-col gap-2">
          <p className="text-sm sm:text-lg">
            <strong className="text-gray-800">Nome:</strong> {pacienteNome}
          </p>
          {pacienteCpf && (
            <p className="text-sm sm:text-lg">
              <strong className="text-gray-800">CPF:</strong> {pacienteCpf}
            </p>
          )}
        </div>
      </div>

      <div className="w-full bg-white rounded-lg drop-shadow p-4">
        <h5 className="text-lg font-bold text-indigo-800 mb-4">
          Detalhes do pagamento{" "}
          <span className="text-sm text-gray-600">
            ({lancamentos.length} itens)
          </span>
        </h5>
        <div className="overflow-x-auto">
          <table className="w-full text-sm sm:text-lg">
            <tbody>
              {lancamentos.map((lancamento, index) => {
                const dataFormatada = lancamento.data.split(" ")[0];
                return (
                  <tr key={index} className="border-b">
                    <td className="px-2 py-2">{lancamento.descricao}</td>
                    <td className="px-2 py-2 hidden sm:table-cell">
                      {dataFormatada}
                    </td>
                    <td className="px-2 py-2 text-right whitespace-nowrap">
                      R${" "}
                      {lancamento.valorPraticado.toFixed(2).replace(".", ",")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr className="font-bold">
                <td className="px-2 py-2 text-sm sm:text-base lg:text-lg">
                  Vencimento: {pagamento.dataVencimento.split(" ")[0]}
                </td>
                <td colSpan="2" className="px-2 py-2 text-right">
                  <span className="text-indigo-800 text-base sm:text-lg lg:text-xl">
                    Total: R$ {valorPagamento.toFixed(2).replace(".", ",")}
                  </span>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="flex flex-col sm:flex-row items-center justify-end gap-4 mt-4">
          <PaymentDetails clientData={clientData} />
          <Button
            className="bg-indigo-700 text-sm px-4 py-2"
            variant="solid"
            onClick={() => onProceedToPayment("PAYMENT_INFO")}
          >
            Pagar
          </Button>
        </div>
      </div>
    </div>
  );
};
