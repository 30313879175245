import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./components/form/form";
import { Spinner } from "components/ui";
import { __buscarPagamentoForma } from "services/CheckoutService";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export const CreditCardDetails = ({ idCliente, hash, onBackToInitial, currentStep, clientData }) => {
  
  const [paymentResponse, setPaymentResponse] = useState(null); 
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (idCliente && hash) {
      __buscarPagamentoForma(idCliente, hash, currentStep)
        .then((response) => {
          setPaymentResponse(response.data); 
        })
        .catch((err) => {
          console.error("Erro ao buscar pagamento:", err);
        })
        .finally(() => {
          setLoading(false);  
        });
    }
  }, [idCliente, hash]);

  if (!paymentResponse?.stpClientSecret) {
    return <Spinner />;
  }

  const options = {
    clientSecret: paymentResponse?.stpClientSecret,
    appearance: {},
  };

  return (
    <>
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm onBackToInitial={onBackToInitial} clientData={clientData} />
      </Elements>
    </>
  );
};
