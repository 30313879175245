import React, { useState } from "react";
import { Button, Dialog } from "components/ui";
import { criarFormatador } from "utils/moneyformatter";

export const PaymentDetails = ({ clientData }) => {
  const formatador = criarFormatador();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const lancamentos = clientData?.lancamentos || [];
  const pagamento = clientData?.pagamento || {};
  const taxaCorpora = clientData.pagamento.valorTaxa;

  const formatDate = (dateString) => {
    return dateString.split(" ")[0];
  };

  return (
    <>
      <Button onClick={handleOpenDialog} variant="outline">
        Detalhes da cobrança
      </Button>

      <Dialog
        isOpen={isOpen}
        onClose={handleCloseDialog}
        onRequestClose={handleCloseDialog}
        width={420}
        height="auto"
      >
        <h4 className="text-lg font-bold mb-4">Detalhes da Cobrança</h4>
        <div className="flex justify-between items-center mb-4">
          <p className="text-gray-800 font-semibold">
            {clientData?.pagamento?.titulo}
          </p>
        </div>
        <div className="mt-4 overflow-y-auto max-h-[400px]">
          {lancamentos.length > 0 ? (
            lancamentos.map((lancamento) => (
              <div
                key={lancamento.uuid}
                className="p-4 mb-4 border border-gray-200 rounded-lg shadow-sm"
              >
                <p className="text-gray-600">
                  <strong className="font-medium text-gray-800">
                    Descrição:
                  </strong>{" "}
                  {lancamento.descricao}
                </p>
                <p className="text-gray-600">
                  <strong className="font-medium text-gray-800">Data:</strong>{" "}
                  {formatDate(lancamento.data)}
                </p>
                <p className="text-gray-600">
                  <strong className="font-medium text-gray-800">
                    Valor Compra:
                  </strong>{" "}
                  {formatador.format(lancamento.valorPraticado)}
                </p>
                <hr className="mt-4 border-gray-200" />
              </div>
            ))
          ) : (
            <p className="text-gray-500">Não há lançamentos disponíveis.</p>
          )}
        </div>

        <div className="mt-4 space-y-2">
          <p className="text-gray-800">
            <strong className="font-medium">Valor Total:</strong>{" "}
            {formatador.format(pagamento.valor)}
          </p>
        </div>
      </Dialog>
    </>
  );
};
