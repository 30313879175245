import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ReactStars from "react-stars";
import { _buscarFeedback, _salvarFeedback } from "services/FeedbackService";
import Tags from "./components/Tags";
import FeedbackSuccess from "./components/FeedbackSuccess";
import ErrorMessage from "./components/ErrorMessage";
import { Spinner } from "components/ui";

const Feedback = () => {
  const { uuidFeedback, urlAgenda } = useParams();
  const [feedbackData, setFeedbackData] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const initialValues = {
    stars: 0,
    testimony: "",
  };

  const isMobile = window.innerWidth <= 375;

  const feedbackSchema = Yup.object().shape({
    stars: Yup.number()
      .min(1, "Por favor, marque a quantidade de estrelas")
      .required("Obrigatório"),
    testimony: Yup.string().max(
      1000,
      "O depoimento pode ter no máximo 1000 caracteres"
    ),
  });

  useEffect(() => {
    const fetchFeedback = async () => {
      try {
        const data = await _buscarFeedback(urlAgenda, uuidFeedback);
        setFeedbackData(data);
      } catch (error) {
        console.error("Erro ao buscar feedback:", error);
        if (error.response && error.response.status === 400) {
          setErrorMessage("O feedback solicitado não existe ou foi removido.");
        } else {
          setErrorMessage("Ocorreu um erro ao carregar o feedback.");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchFeedback();
  }, [uuidFeedback]);

  const handleSubmit = async (values) => {
    const feedback = {
      uuid: uuidFeedback,
      nota: values.stars,
      comentario: values.testimony,
      tags: selectedTags.join(", "),
      urlAgenda: urlAgenda,
    };

    try {
      await _salvarFeedback(feedback);
      setIsFeedbackSubmitted(true);
    } catch (error) {
      console.error("Erro ao salvar feedback:", error);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner size={50} />
      </div>
    );
  }

  if (errorMessage) {
    return <ErrorMessage message={errorMessage} />;
  }

  if (isFeedbackSubmitted) {
    return <FeedbackSuccess />;
  }

  return (
    <div className="flex flex-col items-center p-6 rounded-lg mt-4 w-full max-w-md mx-auto">
      <div className="text-center mb-6">
        <img
          src={
            feedbackData?.data.fotoProfissional || "/path/to/default-photo.jpg"
          }
          alt={feedbackData?.data.nomeProfissional || "Profissional"}
          className="w-20 h-20 rounded-full mx-auto mb-2"
        />
        <h5 className="font-light">
          {feedbackData?.data.nomeProfissional || "Nome do Profissional"}
        </h5>
      </div>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={feedbackSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form className="space-y-4 w-full">
            <div>
              <label className="block text-lg font-light text-gray-700 mb-1">
                Avalie meu atendimento
              </label>
              <div className="flex justify-center">
                <ReactStars
                  count={5}
                  size={isMobile ? 60 : 80}
                  half={false}
                  color1="#b7b7b7"
                  color2={"#fec142"}
                  value={values.stars}
                  onChange={(newValue) => {
                    setFieldValue("stars", newValue);
                    setSelectedTags([]);
                  }}
                />
              </div>
            </div>

            {values.stars > 0 && (
              <Tags
                stars={values.stars}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
              />
            )}

            <div>
              <Field
                as="textarea"
                id="testimony"
                placeholder="Deixe aqui seu depoimento"
                name="testimony"
                rows={4}
                maxLength={1000}
                className="block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
              />
              {errors.testimony && touched.testimony && (
                <p className="text-red-500 text-sm mt-1">{errors.testimony}</p>
              )}
            </div>

            <button
              type="submit"
              className="w-full bg-indigo-700 text-white py-2 px-4 rounded-md shadow hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Enviar
            </button>
          </Form>
        )}
      </Formik>
      <a href="https://www.usecorpora.com.br" className="mb-4 mt-8">
        <img
          src="/img/logo/logo-light-full.png"
          alt="Company Logo"
          className="w-52 mx-auto"
        />
      </a>
    </div>
  );
};

export default Feedback;
