import React from "react";
import { PaymentInfo } from "./components/PaymentInfo";
import { PurchaseDetails } from "./components/PurchaseDetails";
import "./styles.css";

export const InitialDetails = ({ onProceedToPayment, clientData }) => {
  return (
    <div className="container-initial-details">
      <PurchaseDetails onProceedToPayment={onProceedToPayment} clientData={clientData} />
    </div>
  );
};
