import React from "react";

const ErrorMessage = ({ message }) => {
  return (
    <div className="flex flex-col items-center p-6 rounded-lg mt-8 w-full max-w-md mx-auto">
      <h2 className="text-xl font-semibold text-center">
      {message || "Ocorreu um erro ao carregar o feedback."}
      </h2>
      <p className="text-gray-600 text-center mt-4">
        Por favor, verifique o link ou entre em contato com o suporte.
      </p>
      <a href="https://www.usecorpora.com.br" className="mb-4">
        <img
          src="/img/logo/logo-light-full.png"
          alt="Company Logo"
          className="w-52 mx-auto"
        />
      </a>
    </div>
  );
};

export default ErrorMessage;