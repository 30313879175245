import React, { useState } from "react";
import { FiCreditCard, FiShoppingCart, FiArrowLeft } from "react-icons/fi";
import { ImBarcode } from "react-icons/im";
import { FaPix } from "react-icons/fa6";
import { criarFormatador } from "utils/moneyformatter";

export const PaymentInfo = ({ onProceedToPayment, clientData, onBackToInitial }) => {
  const [selectedPayment, setSelectedPayment] = useState(null);

  const formatador = criarFormatador();

  const handlePaymentSelection = (event) => {
    setSelectedPayment(event.target.value);
  };

  const handleProceedToPayment = () => {
    if (selectedPayment) {
      onProceedToPayment(selectedPayment);
    }
  };

  const paymentOptions = clientData.pagamento.formaPagamentoLiberada;

  const valuePaymentCard = clientData.pagamento.valor + clientData.valorTaxaCartao;
  const valuePaymentPix = clientData.pagamento.valor + clientData.valorTaxaPix;


  return (
    <div className="flex flex-col items-center justify-center w-full h-full p-4">
      <div className="flex items-center w-full max-w-md mb-4">
        <button
          onClick={() => onBackToInitial('initial')}
          className="flex items-center gap-2 text-indigo-800 font-medium"
        >
          <FiArrowLeft className="text-xl" />
          Voltar
        </button>
      </div>
      <h4 className="text-xl font-semibold text-indigo-800 mb-4 text-center">
        Selecione a forma de pagamento
      </h4>
      <form className="flex flex-col gap-4 w-full max-w-md">
        {paymentOptions.includes("CARTAO_CREDITO") && (
          <label
            className={`flex items-center p-4 border rounded-lg transition ${
              selectedPayment === "CARTAO_CREDITO" ? "border-indigo-500 bg-indigo-50" : "border-gray-300"
            }`}
          >
            <input
              type="radio"
              name="payment-method"
              value="CARTAO_CREDITO"
              onChange={handlePaymentSelection}
              checked={selectedPayment === "CARTAO_CREDITO"}
              className="w-4 h-4 border-2 border-gray-300 rounded-full text-indigo-600 focus:ring-indigo-500"
            />
            <div className="flex items-center text-lg gap-4 ml-4">
              <FiCreditCard className="text-indigo-500 text-lg" />
              <div>
                <span className="block font-medium text-gray-800">Cartão de Crédito</span>
                {clientData.pagamento.modoTaxacaoEnum !== "PROFISSIONAL" && (
                    <span className="block text-gray-500 text-sm"> tx.Serviço: {formatador.format(clientData.valorTaxaCartao)}</span>
                )}
                <span className="text-gray-500 text-sm"><strong>Total:</strong> 1x {formatador.format(valuePaymentCard)}</span>
              </div>
            </div>
          </label>
        )}
        {paymentOptions.includes("BOLETO") && (
          <label
            className={`flex items-center p-4 border rounded-lg transition ${
              selectedPayment === "BOLETO" ? "border-indigo-500 bg-indigo-50" : "border-gray-300"
            }`}
          >
            <input
              type="radio"
              name="payment-method"
              value="BOLETO"
              onChange={handlePaymentSelection}
              checked={selectedPayment === "BOLETO"}
              className="w-4 h-4 border-2 border-gray-300 rounded-full text-indigo-600 focus:ring-indigo-500"
            />
            <div className="flex items-center gap-4 text-lg ml-4">
              <ImBarcode className="text-indigo-500 text-lg" />
              <span className="font-medium text-gray-800">Boleto</span>
            </div>
          </label>
        )}
        {paymentOptions.includes("PIX") && (
          <label
            className={`flex items-center p-4 border rounded-lg transition ${
              selectedPayment === "PIX" ? "border-indigo-500 bg-indigo-50" : "border-gray-300"
            }`}
          >
            <input
              type="radio"
              name="payment-method"
              value="PIX"
              onChange={handlePaymentSelection}
              checked={selectedPayment === "PIX"}
              className="w-4 h-4 border-2 border-gray-300 rounded-full text-indigo-600 focus:ring-indigo-500"
            />
            <div className="flex text-lg items-center gap-4 ml-4">
              <FaPix className="text-indigo-500 text-lg" />
              <div>
                <span className="block font-medium text-gray-800">Pix</span>
                {clientData.pagamento.modoTaxacaoEnum !== "PROFISSIONAL" && (
                  <span className="block text-gray-500 text-sm">tx.Serviço: {formatador.format(clientData.valorTaxaPix)}</span>
                )}
                <span className="text-gray-500 text-sm"><strong>Total:</strong>  {formatador.format(valuePaymentPix)}</span>
              </div>
            </div>
          </label>
        )}
      </form>
      <button
        className={`mt-6 px-6 py-3 w-full max-w-md text-white font-medium rounded-lg flex items-center justify-center gap-2 transition ${
          selectedPayment ? "bg-indigo-700 hover:bg-indigo-700" : "bg-gray-300 cursor-not-allowed"
        }`}
        disabled={!selectedPayment}
        onClick={handleProceedToPayment}
      >
        <FiShoppingCart className="text-lg" /> Pagar
      </button>
    </div>
  );
};
