import ApiService from "./ApiService";

export const _buscarFeedback = (urlAgenda, uuid) => {
  return ApiService.fetchData({
    url: `/rest/init/terapeuta/feedback/${urlAgenda}/${uuid}`,
    method: "get",
  });
};

export const _salvarFeedback = (feedback) => {
  return ApiService.fetchData({
    url: `/rest/init/terapeuta/feedback`,
    method: "post",
    data: feedback, 
  });
};
